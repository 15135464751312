<template>
  <div class="commonReport">
    <div class="title">
      <h2>Common Report</h2>
    </div>
    <div class="bgf0">
      <el-row type="flex">
        <el-col :span="24" style="padding:16px;">
          <el-card shadow="hover" class="pad30">
            <el-form v-model="commonReport" :inline="true">
              <el-row :gutter="24">
                <el-col :xs="24" :sm="2" :md="2" :lg="2" :xl="2">
                  <label class="labelName">Offer(s)</label>
                </el-col>
                <el-form-item label="OfferId(s)" label-width="1.8rem">
                  <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                    <el-input
                      v-model="commonReport.offerIds"
                      placeholder="offerIds: eg. 2206264 or 2206264,1567396"
                    ></el-input>
                  </el-col>
                </el-form-item>
                <el-form-item>
                  <el-col :xs="1" :sm="1" :md="1" :lg="1" :xl="1">
                    <el-checkbox v-model="offerChecked"></el-checkbox>
                  </el-col>
                </el-form-item>
              </el-row>
              <el-row :gutter="24">
                <el-col :xs="24" :sm="2" :md="2" :lg="2" :xl="2">
                  <label class="labelName">Affiliate(s)</label>
                </el-col>
                <el-form-item label="AffiliateId(s)" label-width="2.08rem">
                  <el-col :xs="24" :sm="20" :md="20" :lg="20" :xl="20">
                    <group-select
                      v-model="commonReport.affiliateIds"
                      :data-source="affiliatesGroupList"
                      multiple
                      :loading="affiliateLoading"
                      clearable
                      reserve-keyword
                      filterable
                      placeholder="请选择"
                      style="width:10.14rem;"
                    />
                  </el-col>
                </el-form-item>
                <el-form-item>
                  <el-col :xs="1" :sm="1" :md="1" :lg="1" :xl="1">
                    <el-checkbox v-model="affiliateChecked"></el-checkbox>
                  </el-col>
                </el-form-item>
              </el-row>
              <el-row :gutter="24">
                <el-col :xs="24" :sm="2" :md="2" :lg="2" :xl="2">
                  <label class="labelName">Source(s)</label>
                </el-col>
                <el-form-item label="Source(s)" label-width="1.8rem">
                  <el-col :xs="24" :sm="20" :md="20" :lg="20" :xl="20">
                    <group-select
                      v-model="commonReport.sourceIds"
                      :data-source="sourcesList"
                      multiple
                      :loading="sourceLoading"
                      reserve-keyword
                      clearable
                      filterable
                      placeholder="请选择"
                      style="width:10.4rem;"
                    />
                  </el-col>
                </el-form-item>
                <el-form-item>
                  <el-col :xs="1" :sm="1" :md="1" :lg="1" :xl="1">
                    <el-checkbox v-model="sourceChecked"></el-checkbox>
                  </el-col>
                </el-form-item>
              </el-row>
              <el-row :gutter="24">
                <el-col :lg="2" :md="2" :xs="24" :sm="2" :xl="2">
                  <label class="labelName">Target(s)</label>
                </el-col>
                <el-form-item label="Countries" label-width="1.8rem">
                  <el-col :lg="6" :md="20" :xs="24" :sm="12" :xl="12">
                    <el-input
                      v-model="commonReport.countries"
                      style="width:2.4rem;"
                      placeholder="eg. US,IT"
                    ></el-input>
                  </el-col>
                </el-form-item>
                <el-form-item>
                  <el-col :lg="1" :md="1" :xs="1" :sm="1" :xl="1">
                    <el-checkbox v-model="countriesChecked"></el-checkbox>
                  </el-col>
                </el-form-item>
                <el-form-item label="Package" label-width="1.2rem">
                  <el-col :lg="7" :md="20" :xs="24" :sm="12" :xl="12">
                    <el-input
                      v-model="commonReport.pkgs"
                      style="width:2.5rem;"
                      placeholder="eg. com.whatsapp,com.nutclean"
                    ></el-input>
                  </el-col>
                </el-form-item>
                <el-form-item>
                  <el-col :lg="1" :md="1" :xs="1" :sm="1" :xl="1">
                    <el-checkbox v-model="packageChecked"></el-checkbox>
                  </el-col>
                </el-form-item>
                <el-form-item label="Platform" label-width="1rem">
                  <el-col :lg="7" :md="20" :xs="24" :sm="12" :xl="12">
                    <el-select
                      v-model="commonReport.platform"
                      style="width:2.5rem;"
                      placeholder="请选择"
                    >
                      <el-option
                        v-for="item in platformOption"
                        :key="item.value"
                        :value="item.value"
                        :label="item.label"
                      ></el-option>
                    </el-select>
                  </el-col>
                </el-form-item>
                <el-form-item>
                  <el-col :lg="1" :md="1" :xs="1" :sm="1" :xl="1">
                    <el-checkbox v-model="platformChecked"></el-checkbox>
                  </el-col>
                </el-form-item>
              </el-row>
              <el-row :gutter="24">
                <el-col :lg="2" :md="2" :xs="24" :sm="2" :xl="2">
                  <label class="labelName">Aff_sub(s)</label>
                </el-col>
                <el-form-item label="Aff_Sub" label-width="1.64rem">
                  <el-col :lg="8" :md="8" :xs="24" :sm="8" :xl="8">
                    <el-input
                      v-model="commonReport.affSubs"
                      style="width:2.8rem;"
                      placeholder="eg. carry_i or carry_i,carry_j"
                    ></el-input>
                  </el-col>
                </el-form-item>
                <el-form-item>
                  <el-col :lg="1" :md="1" :xs="1" :sm="1" :xl="1">
                    <el-checkbox v-model="affSubsChecked"></el-checkbox>
                  </el-col>
                </el-form-item>
              </el-row>
              <el-row>
                <label class="labelName" style="margin-right:20px;">TimeZone</label>
                <el-form-item>
                  <el-select v-model="commonReport.timezone" style="width:420px;">
                    <el-option
                      v-for="item in timezoneOption"
                      :key="item.value"
                      :value="item.value"
                      :label="item.label"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-row>
              <el-row>
                <el-col :lg="3" :md="3" :xs="3" :sm="3" :xl="3">
                  <label class="labelName">Interval</label>
                </el-col>
                <el-col :lg="6" :md="6" :xs="6" :sm="6" :xl="6">
                  <el-form-item label="Month">
                    <el-checkbox v-model="monthChecked"></el-checkbox>
                  </el-form-item>
                </el-col>
                <el-col :lg="6" :md="6" :xs="6" :sm="6" :xl="6">
                  <el-form-item label="Date">
                    <el-checkbox v-model="dateChecked"></el-checkbox>
                  </el-form-item>
                </el-col>
                <el-col :lg="6" :md="6" :xs="6" :sm="6" :xl="6">
                  <el-form-item label="Hour">
                    <el-checkbox v-model="hourChecked"></el-checkbox>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <label class="labelName" style="margin-right:60px;">Date</label>
                <el-form-item>
                  <el-date-picker
                    v-model="startToEndDate"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    type="daterange"
                    style="width:260px;"
                    align="right"
                    unlink-panels
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    :picker-options="pickerOptions"
                  ></el-date-picker>
                </el-form-item>
              </el-row>
              <el-row>
                <el-col :lg="3" :md="3" :xs="3" :sm="3" :xl="3">
                  <label class="labelName" style="margin-right:40px;">Sort by</label>
                </el-col>
                <el-col :lg="20" :md="20">
                  <el-form-item>
                    <el-radio-group v-model="commonReport.sorting">
                      <el-radio
                        style="margin-right:140px;"
                        v-for="item in radioOptions"
                        :key="item"
                        :label="item"
                        >{{ item }}</el-radio
                      >
                    </el-radio-group>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :lg="3" :md="3" :xs="3" :sm="3" :xl="3">
                  <label class="labelName">Revenue info</label>
                </el-col>
                <el-col :lg="6" :md="6" :xs="6" :sm="6" :xl="6">
                  <el-form-item label="ConversionsCount">
                    <el-checkbox v-model="cCountChecked"></el-checkbox>
                  </el-form-item>
                </el-col>
                <el-col :lg="6" :md="6" :xs="6" :sm="6" :xl="6">
                  <el-form-item label="Revenue">
                    <el-checkbox v-model="revenueChecked"></el-checkbox>
                  </el-form-item>
                </el-col>
                <el-col :lg="6" :md="6" :xs="6" :sm="6" :xl="6">
                  <el-form-item label="Total CR">
                    <el-checkbox v-model="totalRevenueChecked"></el-checkbox>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :lg="3" :md="20" :offset="1">
                  <el-form-item>
                    <el-button type="primary" @click="searchListClick(1)">In Browser</el-button>
                  </el-form-item>
                </el-col>
                <el-col :lg="3" :md="20">
                  <el-form-item>
                    <el-button type="primary" @click="exportExcelFileClick"
                      >Download Excel</el-button
                    >
                  </el-form-item>
                </el-col>
                <el-col :lg="3" :md="20" :offset="1">
                  <el-form-item>
                    <el-button :disabled="!offerChecked" type="primary" @click="offerBlackClick"
                      >Offer Black</el-button
                    >
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </el-card>
        </el-col>
      </el-row>
      <el-card shadow="hover">
        <el-table
          v-loading="listLoading"
          ref="tableRef"
          style="width:100%;"
          height="70vmin"
          stripe
          border
          :data="commonReportList"
          highlight-current-row
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="55" v-if="offerChecked"> </el-table-column>
          <el-table-column
            label="Date"
            width="100"
            prop="date"
            align="center"
            :fixed="fullWidth <= 768 ? false : 'left'"
            key="date"
          ></el-table-column>
          <el-table-column
            label="Affiliate ID"
            width="100"
            align="center"
            :fixed="fullWidth <= 768 ? false : 'left'"
            v-if="affiliateChecked"
            key="affiliateId"
          >
            <template slot-scope="scope">
              <span class="cor337ab7" @click="affiliatetailClick(scope.row)">{{
                scope.row.affiliateId
              }}</span>
              <el-tag
                v-if="scope.row.affiliateTrafficTag"
                :color="scope.row.affColor"
                style="color:white;height:24px;line-height:24px;"
              >
                {{ scope.row.affiliateTrafficTag }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column
            label="Affiliate Name"
            width="140"
            :fixed="fullWidth <= 768 ? false : 'left'"
            v-if="affiliateChecked"
            align="center"
            key="affiliateName"
          >
            <template slot-scope="scope">
              <span class="cor337ab7" @click="affiliatetailClick(scope.row)">{{
                scope.row.affiliateName
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="Source ID"
            width="100"
            key="sourceId"
            v-if="sourceChecked"
            align="center"
          >
            <template slot-scope="scope">
              <span class="cor337ab7" @click="sourcedetailClick(scope.row)">{{
                scope.row.sourceId
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="Source Name"
            width="110"
            prop="sourceName"
            v-if="sourceChecked"
            align="center"
            key="sourceName"
          >
            <template slot-scope="scope">
              <span class="cor337ab7" @click="sourcedetailClick(scope.row)">{{
                scope.row.sourceName
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="Prod Name"
            prop="prod"
            width="100"
            key="pkgs"
            v-if="packageChecked"
            align="center"
          >
          </el-table-column>
          <el-table-column
            width="100"
            label="Aff Sub"
            v-if="affSubsChecked"
            align="center"
            key="affSub"
          >
            <template slot-scope="scope">
              <template v-if="scope.row.subpub1">
                <el-popover placement="left" width="200" trigger="hover">
                  <span>FirstLevelSub:</span>
                  <br />
                  <span v-for="(item, index) in scope.row.subpub1.split('-')" :key="index">{{
                    item
                  }}</span>
                </el-popover>
              </template>
              <span>{{ scope.row.affSub }}</span>
              <el-tag
                v-if="scope.row.affSubTrafficTag && scope.row.affSub"
                :color="scope.row.affSubColor"
                style="color:white;height:24px;line-height:24px;"
              >
                {{ scope.row.affSubTrafficTag }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column
            label="Offer ID"
            width="100"
            v-if="offerChecked"
            key="offerId"
            align="center"
          >
            <template slot-scope="scope">
              <span class="cor337ab7" @click="offerdetailClick(scope.row)">{{
                scope.row.offerId
              }}</span>
            </template>
          </el-table-column>
          <el-table-column label="Offer Name" width="100" v-if="offerChecked" align="center">
            <template slot-scope="scope">
              <span class="cor337ab7" @click="offerdetailClick(scope.row)">{{
                scope.row.offerName
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="Offer Status"
            width="110"
            prop="status"
            v-if="offerChecked"
            align="center"
            key="status"
          ></el-table-column>
          <el-table-column
            label="Country"
            width="100"
            prop="country"
            v-if="countriesChecked"
            align="center"
            key="country"
          ></el-table-column>
          <el-table-column label="Total Clicks" width="100" key="totalClicks" show-overflow-tooltip>
            <template slot-scope="scope">
              <span
                v-html="
                  showReportComparingItem(
                    filterNull(scope.row.totalClickCount),
                    filterNull(scope.row.lastTotalClickCount)
                  )
                "
              ></span>
            </template>
          </el-table-column>
          <el-table-column
            label="Clicks"
            width="100"
            align="center"
            key="clicks"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <span
                v-html="
                  showReportComparingItem(
                    filterNull(scope.row.clickCount),
                    filterNull(scope.row.lastClickCount)
                  )
                "
              ></span>
            </template>
          </el-table-column>
          <el-table-column
            label="Aff Conversions"
            width="130"
            align="center"
            key="affConversions"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <span
                v-html="
                  showReportComparingItem(
                    filterNull(scope.row.affConversionCount),
                    filterNull(scope.row.lastConversionCount)
                  )
                "
              ></span>
            </template>
          </el-table-column>
          <el-table-column
            label="Payout"
            width="100"
            align="center"
            key="payout"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <span
                v-html="
                  showReportComparingItem(
                    filterNull(scope.row.totalAdjustPayout).toFixed(2),
                    new Number(filterNull(scope.row.lastTotalAdjustPayout)).toFixed(2)
                  )
                "
              ></span>
            </template>
          </el-table-column>
          <el-table-column label="CR" width="100" align="center" key="cr" show-overflow-tooltip>
            <template slot-scope="scope">
              <span
                v-html="
                  showReportComparingCrItem(
                    filterNull(scope.row.clickCount),
                    filterNull(scope.row.lastClickCount),
                    filterNull(scope.row.affConversionCount),
                    filterNull(scope.row.lastConversionCount)
                  )
                "
              ></span>
            </template>
          </el-table-column>
          <el-table-column
            v-if="cCountChecked"
            label="Conversions"
            width="120"
            align="center"
            prop="conversionCount"
            key="conversionCount"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            v-if="revenueChecked"
            label="Revenue"
            width="100"
            prop="totalRevenue"
            align="center"
            key="totalRevenue"
          >
            <template slot-scope="{ row }">
              <span>{{ row.totalRevenue === '' ? '' : Number(row.totalRevenue).toFixed(2) }}</span>
            </template>
          </el-table-column>
          <el-table-column
            v-if="totalRevenueChecked"
            label="Total CR"
            width="100"
            align="center"
            key="totalCr"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <span
                v-html="
                  showReportComparingCrItem(
                    filterNull(scope.row.clickCount),
                    filterNull(scope.row.lastClickCount),
                    filterNull(scope.row.conversionCount),
                    filterNull(scope.row.lastConversionCount)
                  )
                "
              ></span>
            </template>
          </el-table-column>
          <el-table-column label="RPC" width="100" align="center" key="rpc" show-overflow-tooltip>
            <template slot-scope="scope">
              <span
                v-html="
                  showReportComparingRpcItem(
                    filterNull(scope.row.clickCount),
                    filterNull(scope.row.lastClickCount),
                    filterNull(scope.row.conversionCount),
                    filterNull(scope.row.lastConversionCount)
                  )
                "
              ></span>
            </template>
          </el-table-column>

          <el-table-column
            label="Clicked Offer Count"
            width="160"
            show-overflow-tooltip
            align="center"
            v-if="!offerChecked"
            key="clickedOfferCount"
          >
            <template slot-scope="scope">
              <span
                v-html="
                  showReportComparingItem(
                    filterNull(scope.row.clickOfferCount),
                    filterNull(scope.row.lastClickOfferCount)
                  )
                "
              ></span>
            </template>
          </el-table-column>
          <el-table-column
            label="Converted Offer Count"
            width="180"
            align="center"
            show-overflow-tooltip
            v-if="!offerChecked"
            key="convertedOfferCount"
          >
            <template slot-scope="scope">
              <span
                v-html="
                  showReportComparingItem(
                    filterNull(scope.row.conversionOfferCount),
                    filterNull(scope.row.lastConversionOfferCount)
                  )
                "
              ></span>
            </template>
          </el-table-column>
          <el-table-column
            label="OUR(Offer Utilization Ratio)"
            width="220"
            align="center"
            v-if="!offerChecked"
            show-overflow-tooltip
            key="our"
          >
            <template slot-scope="scope">
              <span
                v-html="
                  showReportComparingCrItem(
                    filterNull(scope.row.clickOfferCount),
                    filterNull(scope.row.lastClickOfferCount),
                    filterNull(scope.row.conversionOfferCount),
                    filterNull(scope.row.lastConversionOfferCount)
                  )
                "
              ></span>
            </template>
          </el-table-column>
          <el-table-column
            label="Platform"
            prop="platform"
            width="100"
            align="center"
            v-if="platformChecked"
            key="platform"
          ></el-table-column>
        </el-table>
        <pagination
          class="block pagePanel"
          :pageTotal="total"
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
          :pageSize="pageParam.pageSize"
          :currentPage="pageParam.page"
        ></pagination>
      </el-card>
    </div>
    <el-dialog
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      :append-to-body="true"
      title="add BlackList"
      width="450px"
    >
      <div class="black-dialog">
        <p>描述：</p>
        <el-input
          type="textarea"
          :autosize="{ minRows: 4, maxRows: 4 }"
          placeholder="请输入内容"
          v-model="blackInfo.description"
        >
        </el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addBlackList">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
  import { api, getCommonReport, addOfferBlack } from 'api/report';
  import Pagination from '@/components/pagination';
  import GroupSelect from '@/components/GroupSelect';
  import { cloneDeep } from 'lodash-es';
  import dateUtils from '@/utils/dateutils.js';
  import qs from 'querystring';
  import { myMixin } from '@/mixins/mixins.js';
  import optionData from '@/assets/js/optionData';
  import { filterObject } from 'utils/utils';
  import { mapState, mapActions } from 'vuex';

  export default {
    mixins: [myMixin],
    components: {
      Pagination,
      GroupSelect,
    },
    data() {
      return {
        pageParam: {
          page: 1,
          pageSize: 20,
        },
        dataParam: null,
        offerChecked: false,
        affiliateChecked: true,
        sourceChecked: false,
        fullWidth: document.documentElement.clientWidth,
        countriesChecked: false,
        packageChecked: false,
        affSubsChecked: false,
        platformChecked: false,
        monthChecked: false,
        dateChecked: true,
        hourChecked: true,
        startToEndDate: '',
        listLoading: false,
        total: 0,
        radioOptions: ['payout', 'clicks', 'conversions'],
        summariesMap: {},
        commonReportList: [],
        timezoneOption: optionData.timezoneOption,
        categoryOption: optionData.categoryOption,
        platformOption: [
          {
            label: 'ALL',
            value: '',
          },
          {
            label: 'ANDROID',
            value: 'ANDROID',
          },
          {
            label: 'IOS',
            value: 'IOS',
          },
        ],
        commonReport: {
          offerIds: null,
          affSubs: null,
          affiliateIds: null,
          columns: [],
          comparing: true,
          countries: null,
          devices: [],
          first_level: 0,
          fromDate: null,
          toDate: null,
          pkgs: null,
          platform: null,
          sorting: 'payout',
          sourceIds: null,
          timezone: '+00:00',
        },
        historyDeductList: [],
        sourceIdArray: [],
        affiliateIdArray: [],
        blackInfo: {
          blackList: [],
          description: '',
        },
        dialogVisible: false,
        cCountChecked: false,
        revenueChecked: false,
        totalRevenueChecked: false,
      };
    },
    mounted() {
      //默认选择
      const end = new Date();
      const start = new Date();
      start.setTime(start.getTime() + 3600 * 1000 * 24);
      this.startToEndDate = [];
      this.startToEndDate[0] = dateUtils.date2StrYMD(end);
      this.startToEndDate[1] = dateUtils.date2StrYMD(start);

      this.getAffiliateList();
      this.getSourceIdsList();
    },
    computed: {
      ...mapState('affiliate', {
        affiliatesGroupList: (state) => state.affiliatesGroupList,
        affiliateLoading: (state) => state.affiliateLoading,
      }),
      ...mapState('source', {
        sourcesList: (state) => state.sourcesList,
        sourceLoading: (state) => state.sourceLoading,
      }),
      listenChange() {
        const {
          affiliateChecked,
          sourceChecked,
          affSubsChecked,
          offerChecked,
          countriesChecked,
          platformChecked,
          packageChecked,
          cCountChecked,
          revenueChecked,
          totalRevenueChecked,
        } = this;
        return {
          affiliateChecked,
          sourceChecked,
          affSubsChecked,
          offerChecked,
          countriesChecked,
          platformChecked,
          packageChecked,
          cCountChecked,
          revenueChecked,
          totalRevenueChecked,
        };
      },
    },
    watch: {
      listenChange: function() {
        this.$nextTick(() => {
          this.$refs.tableRef.doLayout();
        });
      },
    },
    methods: {
      ...mapActions('affiliate', ['getAffiliateList']),
      ...mapActions('source', ['getSourceIdsList']),
      searchListClick(curPage) {
        if (curPage) {
          this.pageParam.page = curPage;
        }
        let param = {
          ...this.commonReport,
          ...this.pageParam,
        };
        if (Array.isArray(param.sourceIds) && param.sourceIds.length > 0) {
          param.sourceIds = param.sourceIds.join(',');
        } else {
          delete param.sourceIds;
        }
        if (Array.isArray(param.affiliateIds) && param.affiliateIds.length > 0) {
          param.affiliateIds = param.affiliateIds.join(',');
        } else {
          delete param.affiliateIds;
        }

        if (this.offerChecked == true && param.columns.indexOf('offer_id') == -1) {
          param.columns.push('offer_id');
        } else if (this.offerChecked == false && param.columns.indexOf('offer_id') !== -1) {
          const offerIdIndex = param.columns.findIndex((item) => {
            if (item == 'offer_id') {
              return true;
            }
          });
          param.columns.splice(offerIdIndex, 1);
        }

        if (
          this.affiliateChecked == true &&
          param.columns.indexOf('affiliate_id') == -1 &&
          param.columns.indexOf('company') == -1
        ) {
          param.columns.push('affiliate_id');
          param.columns.push('company');
        } else if (
          this.affiliateChecked == false &&
          param.columns.indexOf('affiliate_id') !== -1 &&
          param.columns.indexOf('company') !== -1
        ) {
          const affiliateIndex = param.columns.findIndex((item) => {
            if (item == 'affiliate_id') {
              return true;
            }
          });
          param.columns.splice(affiliateIndex, 1);

          const companyIndex = param.columns.findIndex((item) => {
            if (item == 'company') {
              return true;
            }
          });
          param.columns.splice(companyIndex, 1);
        }

        if (
          this.sourceChecked == true &&
          param.columns.indexOf('source_id') == -1 &&
          param.columns.indexOf('source_name') == -1
        ) {
          param.columns.push('source_id');
          param.columns.push('source_name');
        } else if (
          this.sourceChecked == false &&
          param.columns.indexOf('source_id') !== -1 &&
          param.columns.indexOf('source_name') !== -1
        ) {
          const sourceIdIndex = param.columns.findIndex((item) => {
            if (item == 'source_id') {
              return true;
            }
          });
          param.columns.splice(sourceIdIndex, 1);

          const sourceNameIndex = param.columns.findIndex((item) => {
            if (item == 'source_name') {
              return true;
            }
          });
          param.columns.splice(sourceNameIndex, 1);
        }

        if (this.countriesChecked == true && param.columns.indexOf('country') == -1) {
          param.columns.push('country');
        } else if (this.countriesChecked == false && param.columns.indexOf('country') !== -1) {
          const countryIndex = param.columns.findIndex((item) => {
            if (item == 'country') {
              return true;
            }
          });
          param.columns.splice(countryIndex, 1);
        }
        if (this.packageChecked == true && param.columns.indexOf('prod') == -1) {
          param.columns.push('prod');
        } else if (this.packageChecked == false && param.columns.indexOf('prod') !== -1) {
          const prodIndex = param.columns.findIndex((item) => {
            if (item == 'prod') {
              return true;
            }
          });
          param.columns.splice(prodIndex, 1);
        }

        if (this.affSubsChecked == true && param.columns.indexOf('aff_sub') == -1) {
          param.columns.push('aff_sub');
        } else if (this.affSubsChecked == false && param.columns.indexOf('aff_sub') !== -1) {
          const affSubIndex = param.columns.findIndex((item) => {
            if (item == 'aff_sub') {
              return true;
            }
          });
          param.columns.splice(affSubIndex, 1);
        }

        if (this.monthChecked == true && param.columns.indexOf('month') == -1) {
          param.columns.push('month');
        } else if (this.monthChecked == false && param.columns.indexOf('month') !== -1) {
          const monthIndex = param.columns.findIndex((item) => {
            if (item == 'month') {
              return true;
            }
          });
          param.columns.splice(monthIndex, 1);
        }

        if (this.dateChecked == true && param.columns.indexOf('date') == -1) {
          param.columns.push('date');
        } else if (this.dateChecked == false && param.columns.indexOf('date') !== -1) {
          const dateIndex = param.columns.findIndex((item) => {
            if (item == 'date') {
              return true;
            }
          });
          param.columns.splice(dateIndex, 1);
        }

        if (this.hourChecked == true && param.columns.indexOf('hour') == -1) {
          param.columns.push('hour');
        } else if (this.hourChecked == false && param.columns.indexOf('hour') !== -1) {
          const hourIndex = param.columns.findIndex((item) => {
            if (item == 'hour') {
              return true;
            }
          });
          param.columns.splice(hourIndex, 1);
        }

        if (this.platformChecked == true && param.columns.indexOf('platform') == -1) {
          param.columns.push('platform');
        } else if (this.platformChecked == false && param.columns.indexOf('platform') !== -1) {
          const platformIndex = param.columns.findIndex((item) => {
            if (item == 'platform') {
              return true;
            }
          });
          param.columns.splice(platformIndex, 1);
        }
        if (this.startToEndDate.length === 2) {
          let startDate = this.startToEndDate[0];
          let endDate = this.startToEndDate[1];
          param.fromDate = startDate;
          param.toDate = endDate;
        }
        this.pageParam.page = 1;

        const dataParam = cloneDeep(param);
        if (Array.isArray(dataParam.columns)) {
          dataParam.columns = dataParam.columns.join(',');
        }
        this.dataParam = dataParam;

        this.commonReportApi();
      },
      commonReportApi() {
        this.listLoading = true;
        let param = {
          ...this.dataParam,
          ...this.pageParam,
        };
        param = filterObject(param);
        getCommonReport(param).then((response) => {
          this.listLoading = false;
          if (response.code === 200) {
            const result = response.result || {};
            console.log(result?.dataList, 'result');
            this.commonReportList = result?.dataList?.map((item) => {
              if (item.affiliateTrafficTag) {
                const arr = item.affiliateTrafficTag.split('-');

                item.affColor =
                  arr[0] === 'red' ? '#FF5722' : arr[0] === 'green' ? '#5F9EA0' : '#D8CAAF';

                item.affiliateTrafficTag = arr[1];
              }
              if (item.affSubTrafficTag) {
                const arr = item.affSubTrafficTag.split('-');
                item.affSubColor =
                  arr[0] === 'red' ? '#FF5722' : arr[0] === 'green' ? '#5F9EA0' : '#D8CAAF';
                item.affSubTrafficTag = arr[1];
              }
              return item;
            });
            console.log('commonReportList', this.commonReportList);
            this.summariesMap = result?.total;
            this.total = result?.total?.count;
            if (this.summariesMap) {
              const totalObject = new Object();
              totalObject.date = 'Total';
              totalObject.totalClickCount = this.summariesMap.totalClickCount;
              totalObject.affConversionCount = this.summariesMap.affConversionCount;
              totalObject.clickCount = this.summariesMap.clickCount;
              totalObject.lastClickCount = this.summariesMap.lastClickCount;
              totalObject.conversionCount = this.summariesMap.conversionCount;
              totalObject.lastConversionCount = this.summariesMap.lastConversionCount;
              totalObject.totalPayout = this.summariesMap.totalPayout;
              totalObject.lastTotalPayout = this.summariesMap.lastTotalPayout;
              totalObject.totalAdjustPayout = this.summariesMap.totalAdjustPayout;
              totalObject.lastTotalAdjustPayout = this.summariesMap.lastTotalAdjustPayout;
              totalObject.totalRevenue = this.summariesMap.totalRevenue;
              totalObject.lastTotalRevenue = this.summariesMap.lastTotalRevenue;
              totalObject.clickOfferCount = this.summariesMap.clickOfferCount;
              totalObject.lastClickOfferCount = this.summariesMap.lastClickOfferCount;
              totalObject.conversionOfferCount = this.summariesMap.conversionOfferCount;
              totalObject.lastConversionOfferCount = this.summariesMap.lastConversionOfferCount;
              this.commonReportList.push(totalObject);
            }
          } else {
            this.commonReportList = [];
            this.summariesMap = {};
            this.total = null;
            this.$message.error(response.message);
          }
        });
      },
      exportExcelFileClick() {
        if (this.sourceIdArray != [] && this.sourceIdArray.length > 0) {
          this.commonReport.sourceIds = this.sourceIdArray;
        }

        if (this.affiliateIdArray != [] && this.affiliateIdArray.length > 0) {
          this.commonReport.affiliateIds = this.affiliateIdArray;
        }

        let param = {};
        let columns = new Array();

        if (
          this.affiliateChecked == true &&
          columns.indexOf('affiliate_id') == -1 &&
          columns.indexOf('company') == -1
        ) {
          columns.push('affiliate_id');
          columns.push('company');
        } else if (
          this.affiliateChecked == false &&
          columns.indexOf('affiliate_id') !== -1 &&
          columns.indexOf('company') !== -1
        ) {
          const affiliateIndex = columns.findIndex((item) => {
            if (item == 'affiliate_id') {
              return true;
            }
          });
          columns.splice(affiliateIndex, 1);

          const companyIndex = columns.findIndex((item) => {
            if (item == 'company') {
              return true;
            }
          });
          columns.splice(companyIndex, 1);
        }

        if (
          this.sourceChecked == true &&
          columns.indexOf('source_id') == -1 &&
          columns.indexOf('source_name') == -1
        ) {
          columns.push('source_id');
          columns.push('source_name');
        } else if (
          this.sourceChecked == false &&
          columns.indexOf('source_id') !== -1 &&
          columns.indexOf('source_name') !== -1
        ) {
          const sourceIdIndex = columns.findIndex((item) => {
            if (item == 'source_id') {
              return true;
            }
          });
          columns.splice(sourceIdIndex, 1);

          const sourceNameIndex = columns.findIndex((item) => {
            if (item == 'source_name') {
              return true;
            }
          });
          columns.splice(sourceNameIndex, 1);
        }

        if (this.offerChecked == true && columns.indexOf('offer_id') == -1) {
          columns.push('offer_id');
        } else if (this.offerChecked == false && columns.indexOf('offer_id') !== -1) {
          const offerIdIndex = columns.findIndex((item) => {
            if (item == 'offer_id') {
              return true;
            }
          });
          columns.splice(offerIdIndex, 1);
        }

        if (this.countriesChecked == true && columns.indexOf('country') == -1) {
          columns.push('country');
        } else if (this.countriesChecked == false && columns.indexOf('country') !== -1) {
          const countryIndex = columns.findIndex((item) => {
            if (item == 'country') {
              return true;
            }
          });
          columns.splice(countryIndex, 1);
        }

        if (this.packageChecked == true && columns.indexOf('prod') == -1) {
          columns.push('prod');
        } else if (this.packageChecked == false && columns.indexOf('prod') !== -1) {
          const prodIndex = columns.findIndex((item) => {
            if (item == 'prod') {
              return true;
            }
          });
          columns.splice(prodIndex, 1);
        }

        if (this.affSubsChecked == true && columns.indexOf('aff_sub') == -1) {
          columns.push('aff_sub');
        } else if (this.affSubsChecked == false && columns.indexOf('aff_sub') !== -1) {
          const affSubIndex = columns.findIndex((item) => {
            if (item == 'aff_sub') {
              return true;
            }
          });
          columns.splice(affSubIndex, 1);
        }

        if (this.monthChecked == true && columns.indexOf('month') == -1) {
          columns.push('month');
        } else if (this.monthChecked == false && columns.indexOf('month') !== -1) {
          const monthIndex = columns.findIndex((item) => {
            if (item == 'month') {
              return true;
            }
          });
          columns.splice(monthIndex, 1);
        }

        if (this.dateChecked == true && columns.indexOf('date') == -1) {
          columns.push('date');
        } else if (this.dateChecked == false && columns.indexOf('date') !== -1) {
          const dateIndex = columns.findIndex((item) => {
            if (item == 'date') {
              return true;
            }
          });
          columns.splice(dateIndex, 1);
        }

        if (this.hourChecked == true && columns.indexOf('hour') == -1) {
          columns.push('hour');
        } else if (this.hourChecked == false && columns.indexOf('hour') !== -1) {
          const hourIndex = columns.findIndex((item) => {
            if (item == 'hour') {
              return true;
            }
          });
          columns.splice(hourIndex, 1);
        }

        param['fromDate'] = this.startToEndDate[0];
        param['toDate'] = this.startToEndDate[1];
        if (this.commonReport.timezone != null) {
          param['timezone'] = this.commonReport.timezone;
        }

        if (this.commonReport.sourceIds != null && this.commonReport.sourceIds.length > 0) {
          param['sourceIds'] = this.commonReport.sourceIds + '';
        }
        if (this.commonReport.affiliateIds != null) {
          param['affiliateIds'] = this.commonReport.affiliateIds + '';
        }
        if (this.commonReport.affSubs != null) {
          param['affSubs'] = this.commonReport.affSubs;
        }
        if (this.commonReport.offerIds != null) {
          param['offerIds'] = this.commonReport.offerIds;
        }
        if (this.commonReport.countries != null) {
          param['countries'] = this.commonReport.countries;
        }
        if (this.commonReport.pkgs != null) {
          param['pkgs'] = this.commonReport.pkgs;
        }
        if (columns != null) {
          param['columns'] = columns.join(',');
        }
        if (this.commonReport.platform != null && this.commonReport.platform != '') {
          param['platform'] = this.commonReport.platform;
        }
        param = filterObject(param);
        let strParam = qs.stringify(param);

        window.open(`${api.DOWNLOAD_COMMIT_REPORT}?${strParam}`, '_blank');
      },
      handleSizeChange(size) {
        this.pageParam.pageSize = size;
        this.handleCurrentChange(1);
      },
      handleCurrentChange(currentPage) {
        this.pageParam.page = currentPage;
        this.commonReportApi();
      },
      affiliatetailClick(row) {
        const { href } = this.$router.resolve({
          path: '/affiliate/detail',
          query: {
            affiliateId: row.affiliateId,
          },
        });
        window.open(href, '_blank');
      },
      sourcedetailClick(row) {
        const { href } = this.$router.resolve({
          path: '/source/detail',
          query: {
            sourceId: row.sourceId,
          },
        });
        window.open(href, '_blank');
      },
      offerdetailClick(row) {
        const { href } = this.$router.resolve({
          path: '/offer/detail',
          query: {
            offerId: row.offerId,
          },
        });
        window.open(href, '_blank');
      },
      filterNull(val) {
        return val == null ? 0 : val;
      },
      showReportComparingItem(cur, last) {
        const pecent =
          cur == last
            ? '--'
            : 0 == last
            ? '100%'
            : new Number(((cur - last) / last) * 100).toFixed(2) + '%';
        const color = cur > last ? 'green' : cur == last ? 'silver' : 'red';
        let result = cur;
        if (last != 0) {
          result += '<span style="color:' + color + '">(' + last + ', ' + pecent + ')</span>';
        }
        return result;
      },
      showReportComparingCrItem(curClicks, lastClicks, curConvs, lastConvs) {
        const cur = new Number((curConvs / (0 == curClicks ? 1 : curClicks)) * 100).toFixed(6);

        let result = cur + '%';
        if (lastClicks != 0) {
          const last = new Number((lastConvs / (0 == lastClicks ? 1 : lastClicks)) * 100).toFixed(
            6
          );
          const pecent =
            cur == last
              ? '--'
              : 0 == last
              ? '100%'
              : new Number(((cur - last) / last) * 100).toFixed(6) + '%';
          const color = cur > last ? 'green' : cur == last ? 'silver' : 'red';
          result += "<span style='color:" + color + "'>(" + last + '%, ' + pecent + ')</span>';
        }
        return result;
      },
      showReportComparingRpcItem(curClicks, lastClicks, curPayout, lastPayout) {
        const cur = new Number(curPayout / (0 == curClicks ? 1 : curClicks)).toFixed(5);
        const last = new Number(lastPayout / (0 == lastClicks ? 1 : lastClicks)).toFixed(5);
        const color = cur > last ? 'green' : cur == last ? 'silver' : 'red';
        let result = cur;
        if (lastClicks != 0) {
          const pecent =
            cur == last
              ? '--'
              : 0 == last
              ? '100%'
              : new Number(((cur - last) / last) * 100).toFixed(2) + '%';
          result += "<span style='color:" + color + "'>(" + last + '%, ' + pecent + ')</span>';
        }
        return result;
      },
      handleSelectionChange(val) {
        this.blackInfo.blackList = val.map((item) => ({
          offerId: item.offerId || '',
          affiliateId: item.affiliateId,
        }));
      },
      offerBlackClick() {
        if (!this.blackInfo.blackList || this.blackInfo.blackList.length === 0) {
          this.$message.warning('Please select the row to mask');
          return;
        }
        this.dialogVisible = true;
      },
      addBlackList() {
        if (!this.blackInfo.description) {
          this.$message.warning('Please enter description text');
          return;
        }
        const params = {
          ...this.blackInfo,
        };
        addOfferBlack(params).then((response) => {
          if (response.code === 200) {
            this.$message.success('Blacklist added successfully');
            this.searchListClick(1);
            this.dialogVisible = false;
          } else {
            this.message.error('Blacklist addition failure');
          }
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
  .black-dialog {
    width: 400px;
    p {
      line-height: 30px;
    }
    input {
      width: 300px;
    }
  }
</style>
<style lang="scss"></style>
